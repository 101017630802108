import * as React from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'

const Inquiry: React.FC = () => (
  <section id="inquiry">
    <h1 className="fadeup">
      <FormattedMessage id="inquiry_title" />
    </h1>
    <button className="btn-primary reveal" type="button">
      <Link to="/contact">
        <FormattedMessage id="inquiry_button" />
      </Link>
    </button>
  </section>
)

export default Inquiry
