import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage, getSrc, getSrcSet } from 'gatsby-plugin-image'
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks'
import { Swiper, SwiperSlide } from 'swiper/react'

import { AllScrollPressDataQuery } from '../../types/graphql-types'

type Props = {
  offset: number
  language: string
}

const ScrollPress: React.FC<Props> = ({ offset, language }) => {
  const data: AllScrollPressDataQuery = useStaticQuery(
    graphql`
      query allScrollPressData {
        allContentfulScrollPress(sort: { fields: priority, order: ASC }) {
          edges {
            node {
              id
              node_locale
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 40)
                  }
                }
              }
              title
              comment {
                comment
              }
            }
          }
        }
      }
    `,
  )

  const { edges } = data.allContentfulScrollPress

  return (
    <section id="press">
      <h1 className="fadeup">Press</h1>
      <Swiper
        tag="div"
        wrapperTag="ul"
        loop
        spaceBetween={24}
        slidesPerView={1.4}
        loopedSlides={edges.length}
        slidesPerGroup={1}
        slidesOffsetBefore={offset}
        centeredSlides
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
          840: {
            centeredSlides: false,
            slidesPerView: 'auto',
          },
        }}
      >
        {edges
          .filter(value =>
            (value.node.node_locale as string).startsWith(language),
          )
          .map(value => {
            const imageFileNode = value.node.logo?.localFile as FileNode
            const image = getImage(imageFileNode)
            const imageSrc = getSrc(imageFileNode)
            const imageSrcSet = getSrcSet(imageFileNode)

            return (
              <SwiperSlide tag="li" key={value.node.id}>
                <div className="logo">
                  {image && imageSrc && imageSrcSet ? (
                    <img
                      src={imageSrc}
                      srcSet={imageSrcSet}
                      width={image.width}
                      height={image.height}
                      alt={(value.node.title as string) ?? 'logo'}
                      loading="eager"
                    />
                  ) : (
                    ''
                  )}
                </div>
                <p className="text">{value.node.comment?.comment ?? ''}</p>
              </SwiperSlide>
            )
          })}
        <div className="swiper-button-prev pc fadeup-delay" />
        <div className="swiper-button-next pc fadeup-delay" />
      </Swiper>
      <button className="btn-white fadeup" type="button">
        <Link to="/press">More</Link>
      </button>
    </section>
  )
}

export default ScrollPress
