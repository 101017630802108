import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc, getSrcSet } from 'gatsby-plugin-image'
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks'

import { AllAwardsDataQuery } from '../../types/graphql-types'

const Awards: React.FC = () => {
  const data: AllAwardsDataQuery = useStaticQuery(
    graphql`
      query allAwardsData {
        allContentfulAwards(
          filter: { node_locale: { regex: "/^en/" } }
          sort: { fields: priority, order: ASC }
        ) {
          edges {
            node {
              id
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 80)
                  }
                }
              }
              name
              priority
            }
          }
        }
      }
    `,
  )

  const { edges } = data.allContentfulAwards

  return (
    <section id="investors">
      <div className="inner">
        <h1 className="fadeup">Awards</h1>
        <ul>
          {edges.map(value => {
            const imageFileNode = value.node.logo?.localFile as FileNode
            const image = getImage(imageFileNode)
            const imageSrc = getSrc(imageFileNode)
            const imageSrcSet = getSrcSet(imageFileNode)

            return (
              <li className="fadeup" key={value.node.id}>
                {image && imageSrc && imageSrcSet ? (
                  <img
                    src={imageSrc}
                    srcSet={imageSrcSet}
                    width={image.width}
                    height={image.height}
                    alt={(value.node.name as string) ?? 'logo'}
                  />
                ) : (
                  ''
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Awards
