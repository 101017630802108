/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react'
import { PageProps, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-intl'

import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.scss'

import { addScrollToAnchor, getRedirectLanguage } from '../utils'
import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import Inquiry from '../components/inquiry'
import Awards from '../components/awards'
import ScrollPress from '../components/scroll-press'

// import '../styles/swiper.css'

SwiperCore.use([Navigation])

const calcOffset = () => {
  if (typeof window !== `undefined`) {
    return window.innerWidth > 1128 ? (window.innerWidth - 1128) / 2 : 0
  }

  return 0
}

const IndexPage: React.FC<PageProps> = ({ location, pageContext }) => {
  React.useEffect(() => {
    const urlLang = getRedirectLanguage()
    if (location.pathname === '/' && location.pathname !== urlLang) {
      void navigate(urlLang)
    } else if (location.pathname === '/en/' && urlLang === '/') {
      void navigate(location.pathname.replace('/en/', '/'))
    }

    addScrollToAnchor()
  }, [location.pathname])
  const intl = useIntl()
  const { language } = pageContext as { language: string }

  const swiperOffset = calcOffset()
  const topVideoURL =
    'https://www.youtube.com/embed/_nHB1Z0DGoU?autoplay=1&mute=1&playsinline=1&loop=1&playlist=_nHB1Z0DGoU&controls=0&showinfo=0&iv_load_policy=3&fs=0&modestbranding=1'

  return (
    <Layout id="home" pageContext={pageContext}>
      <section id="top">
        <Header location={location} pageContext={pageContext} />

        <div className="main-wrap inner">
          <div className="main-text">
            <h2 className="fadeup">
              {intl.formatMessage({ id: 'index_main_text' })}
            </h2>
            <button className="btn-primary fadeup-delay" type="button">
              <Link to="/contact">
                {intl.formatMessage({ id: 'index_contact_us' })}
              </Link>
            </button>
          </div>

          <div className="main-visual fadeup pc">
            <iframe
              src={topVideoURL}
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="main visual"
            />
          </div>
        </div>
        <div className="main-visual fadeup-delay sp">
          <iframe
            src={topVideoURL}
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="main visual"
          />
        </div>
      </section>

      <section id="vision">
        <div className="inner">
          <div className="vision-text">
            <h1 className="heading fadeup">
              {intl.formatMessage({ id: 'index_vision_title' })}
              {intl.formatMessage({ id: 'index_vision_title_2' }) !== ' ' ? (
                <br />
              ) : (
                ''
              )}
              {intl.formatMessage({ id: 'index_vision_title_2' })}
            </h1>
            <p className="fadeup-delay">
              {intl.formatMessage({ id: 'index_vision_text' })}
            </p>
          </div>
          <div className="vision-visual fadeup">
            <StaticImage
              src="../images/our-vision.jpg"
              alt="our-vision"
              loading="eager"
            />
          </div>
        </div>
      </section>

      <section id="feature" className="fadeup">
        <div className="feature-wrap">
          <h2 className="fadeup">
            {intl.formatMessage({ id: 'index_feature_1_title' })}
          </h2>
          <div className="sp fadeup-delay">
            <div className="video-wrap sp">
              <img src="/images/frame.png" alt="background" loading="eager" />
              <video
                className="sp"
                src="/videos/mozart_sp.mp4"
                poster="/images/mozart_sp.gif"
                muted
                autoPlay
                loop
              />
            </div>
          </div>

          <p className="fadeup">
            {intl.formatMessage({ id: 'index_feature_1_text' })}
            {language === 'ja' ? <br /> : ''}
            &nbsp;
          </p>
          <div className="pc fadeup-delay">
            <div className="video-wrap pc">
              <img src="/images/frame.png" alt="background" loading="eager" />
              <video
                className="pc fadeup-delay"
                src="/videos/mozart_pc.mp4"
                poster="/images/mozart_pc.gif"
                muted
                autoPlay
                loop
              />
            </div>
          </div>
        </div>
        <div className="feature-wrap">
          <h2 className="fadeup">
            {intl.formatMessage({ id: 'index_feature_2_title' })}
          </h2>
          <div className="sp fadeup-delay">
            <div className="video-wrap sp">
              <img src="/images/frame.png" alt="background" loading="eager" />
              <video
                className="sp fadeup-delay"
                src="/videos/3ddensefacetracking_sp.mp4"
                poster="/images/3ddensefacetracking_sp.gif"
                muted
                autoPlay
                loop
              />
            </div>
          </div>
          <p className="fadeup">
            {intl.formatMessage({ id: 'index_feature_2_text' })}
          </p>
          <div className="pc fadeup-delay">
            <div className="video-wrap pc">
              <img src="/images/frame.png" alt="background" loading="eager" />
              <video
                className="pc"
                src="/videos/3ddensefacetracking_pc.mp4"
                poster="/images/3ddensefacetracking_pc.gif"
                muted
                autoPlay
                loop
              />
            </div>
          </div>
        </div>

        <h1 id="products" className="fadeup">
          {intl.formatMessage({ id: 'index_products_title' })}
        </h1>
        <div className="products-wrap xpression-camera fadeup">
          <div className="icon">
            <img
              src="/images/icon-camera.png"
              srcSet="/images/icon-camera.png 1x, /images/icon-camera@2x.png 2x"
              width={240}
              height={240}
              alt="xpression camera icon"
              loading="eager"
            />
          </div>
          <h2>
            xpression <span>camera</span>
          </h2>
          <p>{intl.formatMessage({ id: 'index_product_1_text' })}</p>
          <button className="btn-secondary" type="button">
            <a
              href="https://xpressioncamera.com/"
              target="_blank"
              rel="noreferrer"
            >
              More
            </a>
          </button>
        </div>
        <div className="products-wrap xpression-chat fadeup">
          <div className="icon">
            <img
              src="/images/icon-chat.png"
              srcSet="/images/icon-chat.png 1x, /images/icon-chat@2x.png 2x"
              width={240}
              height={240}
              alt="xpression chat icon"
              loading="eager"
            />
          </div>
          <h2>
            xpression <span>chat</span>
          </h2>
          <p>{intl.formatMessage({ id: 'index_product_2_text' })}</p>
          <button className="btn-secondary" type="button">
            <a
              href="https://xpressionchat.com/"
              target="_blank"
              rel="noreferrer"
            >
              More
            </a>
          </button>
        </div>
        <div className="products-wrap xpression-avatar fadeup">
          <div className="icon">
            <img
              src="/images/icon-avatar.png"
              srcSet="/images/icon-avatar.png 1x, /images/icon-avatar@2x.png 2x"
              width={240}
              height={240}
              alt="xpression avatar icon"
              loading="eager"
            />
          </div>
          <h2>
            xpression <span>avatar</span>
          </h2>
          <p>{intl.formatMessage({ id: 'index_product_3_text' })}</p>
          <button className="btn-secondary" type="button">
            <a
              href="https://xpressionavatar.com/"
              target="_blank"
              rel="noreferrer"
            >
              More
            </a>
          </button>
        </div>
        <div className="products-wrap xpression-sdk fadeup">
          <div className="icon">
            <img
              src="/images/icon-sdk.png"
              srcSet="/images/icon-sdk.png 1x, /images/icon-sdk@2x.png 2x"
              width={240}
              height={240}
              alt="xpression sdk icon"
              loading="eager"
            />
          </div>
          <h2>
          Real-time AI video generation SDK 
          </h2>
          <p>{intl.formatMessage({ id: 'index_product_4_text' })}</p>
          <button className="btn-secondary" type="button">
            <a
              href="https://embodyme.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              More
            </a>
          </button>
        </div>
      </section>

      <Awards />
      <ScrollPress offset={swiperOffset} language={language} />
      <Inquiry />
      <Footer location={location} />
    </Layout>
  )
}

export default IndexPage
